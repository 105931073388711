/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Fullmap, FullmapWrap, ColumnWrap, Title, Image, SeparateLine } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Pro rodiče"}>
        <ColumnCover name={"menu"}>
        </ColumnCover>


        <Column className="--menu pb--10 pt--10" menu={true}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box" content={"<a href=\"/\">Mateřská škola <span style=\"font-weight: bold;\">Litultovice</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/"} target={""} content={"<span style=\"color: rgb(115, 71, 156);\">Aktuálně</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/dokumenty"} target={""} content={"<span style=\"color: rgb(235, 131, 207);\">Dokumenty</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/pro-rodice"} target={""} content={"<span style=\"color: rgb(55, 116, 222); text-decoration: underline;\">Pro <span style=\"text-decoration: underline;\">rodiče</span></span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/o-nas"} target={""} content={"<span style=\"color: rgb(16, 156, 69);\">O nás</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/kontakt"} target={""} content={"<span style=\"color: rgb(213, 4, 57);\">Kontakt</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-qbd661 --justify mb--0 mt--0 pb--80 pr--0 pt--80" style={{"backgroundColor":"var(--color-variable-1)"}} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Fullmap className="--style2" name={"xrq5c45sffc"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <ColumnCover name={"8nlzg5iq4eb"}>
        </ColumnCover>


        <Column className="--center" style={{"paddingBottom":11,"paddingTop":31}} name={"k2w01omg5z"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(250, 234, 108);\">Pro rodiče</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l8"} name={"98po66vzcjh"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingLeft":94,"paddingRight":118}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13139/004de05161394d4dbe40de6ec3f17d36_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13139/004de05161394d4dbe40de6ec3f17d36_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13139/004de05161394d4dbe40de6ec3f17d36_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13139/004de05161394d4dbe40de6ec3f17d36_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="css-q8ux01 css-ns06ek" style={{"paddingTop":35,"paddingBottom":24,"marginBottom":6}} name={"6xnkrh8yfb"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=3000x_.png);
    }
  
background-position: 50% 84%;
    `}>
        </SeparateLine>

      </Layout>
    </ThemeWrapper>
  )
}